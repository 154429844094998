// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/WLegislation/lines.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".part3[data-v-4b9a6b55] {\n  height: 605px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center top 280px #fff;\n  background-size: 100% auto;\n}\n.part4[data-v-4b9a6b55] {\n  height: 610px;\n  background: #eaf3ff;\n  width: 100%;\n}\n.part5[data-v-4b9a6b55] {\n  padding-top: 70px;\n}\n.common-cont[data-v-4b9a6b55] {\n  background: #fff;\n}\n", ""]);
// Exports
module.exports = exports;
