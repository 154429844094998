export const allData = {
  features: [
    {
      name: '全过程管理 强化监督',
      content:
        '严格落实立法法规定，计划、起草、意见征集、审查、清理、评估过程处处留痕，成果可追溯'
    },
    {
      name: '智能化辅助 提高质量',
      content:
        '实现全程提供智能化审查工具，提供智能辅助起草、智能版本对照、书面意见汇总、公开意见处理、智能辅助审核、智能辅助审查、智能预警提醒、智能清理，提升文件质效'
    },
    {
      name: '专业化智库 夯实基础',
      content:
        '内置现行有效及历史沿革的法律、行政法规、部门规章、地方性法规、地方政府规章等文件，提供全方位参考及辅助'
    },
    {
      name: '多渠道参与 民主立法',
      content:
        '通过计划、草案、评估环节多方位加强公众参与度，提升意见征集广度和深度'
    },
    {
      name: '数字化管理 考核达标',
      content:
        '满足法治政府考核、示范创建等法治政府建设对于地方政府规章、规范性文件管理的要求'
    }
  ],
  cases: [
    {
      img: 'lf-case1.png',
      name: '司法部行政立法工作管理系统',
      content:
        '司法部行政立法工作管理系统涵盖立法计划、意见征集、法规审查、条约审查、法规译审、提请审查、法律法规库等内容，内置立法综合监控、法规条文智能拆分、意见反馈汇总处理、版本更新动态追踪、对照表即时生成等辅助工具，为行政立法的科学制定、民主参与和法治实施提供强有力的技术支持，全面构建高效、透明、规范的行政立法管理框架'
    },
    {
      img: 'gzsfzypt.png',
      name:'贵州省政府立法管理平台',
      content:'贵州省政府立法管理平台涵盖立法计划、意见征集、规范性文件审查等业务，实现地方性法规、政府规章制定的全过程动态管理，通过意见征集系统广泛吸收社会公众的立法意见，提高政府立法的科学化、民主化、规范化水平，提升立法质量和立法效率'
    },
    {
      img: 'gdslfscgj.png',
      name: '广东省政府立法工作管理平台',
      content:'广东省政府立法工作管理平台建设政府立法业务管理、立法资料管理、立法审查工具等，实现立法业务全过程管理，通过智能审查编辑、智能条款关联、智能版本比对、智能重复预警等辅助工具，提高立法审查质效，促进科学立法、民主立法和依法立法的深入实践，为法治广东立法工作注入科技能量'
    }
  ],
  intro: [
    {
      name: '综合立法过程管理',
      content:
        '实现法规规章及行政规范性文件过程性及规范性管理，支持计划、起草、审核、意见征集、签发、备案、公示、清理、提请审查、评估等全业务过程规范办理，根据过程中的资料一键生成统一的文件资源库，实现对全生命周期的动态跟踪，保证准确度及时效性'
    },
    {
      name: '智能辅助起草',
      content:
        '辅助起草草案文本，实现集资料推荐、检索、协同编辑与一键导出功能于一身。依托专业法律法规库，根据立法需求通过自然语言处理技术自动推荐相关法规资料，按照格式要求一键生成规范草案文本，减少找立法资料困难、大量重复性工作情况，极大提高草案起草质效'
    },
    {
      name: '智能版本对照',
      content:
        '对比不同版本的草案文本，实现智能对比版本差异，一键生成对照稿及花脸稿。通过基于自然语言处理技术快速找出稿件间的差异和变化，高亮标识版本间的差异性，过程中随时一键生成对照稿及花脸稿，极大提高立法工作的效率'
    },
    {
      name: '书面意见汇总',
      content:
        '辅助处理汇总线下各部门、立法联络点反馈的意见函，实现智能识别提取意见，一键生成意见汇总表。运用文本识别、实体关系抽取等深度学习技术，自动识别并提取意见函内容生成结构化意见，在自动生成的结构化数据条款基础上汇总，辅助立法人员快速整理意见，一键生成意见汇总表'
    },
    {
      name: '公开意见处理',
      content:
        '辅助处理社会公众反馈的意见条款，实现智能识别汇总意见并一键生成意见简报。运用大语言模型理解能力、推理能力及生成能力，自动汇总识别正反向意见，归纳相似意见，快速识别关键意见，协助立法人员快速识别公众意见，轻松把握公众心声，一键生成意见简报'
    },
    {
      name: '智能辅助审查',
      content:
        '依托动态法律法规库，通过自然语言处理技术和法治大模型对审查文件内容进行深度分析，结合语义识别内容并自动推送制定依据及相关文件进行对照。根据立法法、立法审查技术规范等相关制度文件要求建立智能审查模型，实现智能预警合宪合法合规风险，一键生成审查报告。减少人工查找文件难，提高审查效率'
    },
    {
      name: '公开征求意见',
      content:
        '涵盖PC+移动多终端征集公众意见，实现了意见处理、分析和反馈机制，加强立法计划、立法草案、立法评估意见的相关意见征集，完善公众参与机制，拓展公众参与渠道，提高政府立法透明度'
    },
    {
      name: '法治资源库',
      content:
        '涵盖宪法法律、行政法规、部门规章等中央法规和地方性法规、地方政府规章、规范性文件等地方法规，支持使用标题检索、全文检索、标签检索、精确检索、模糊检索、高级检索等多种方式检索，同时支持现行版、历史版的切换、上位法查看，支持通过发布机关、行政区划、时效性等维度精准定位'
    }
  ],
  moreCases: [
    {
      img: 'qgfggzbascgzpt.png',
      name: '全国法规规章备案审查工作平台',
      content:
        '围绕法规规章备案业务，对全国法规规章的立改废等立法活动提供指导和帮助，落实法规规章线上线下备案审查制度。实现为相关业务系统提供完善准确的数据支撑，同时为社会公众提供统一、标准、规范的法规规章信息查询服务'
    },
    {
      img: 'gjxzfgk.png',
      name: '国家行政法规库',
    },
    {
      img: 'sfbgkzqyj.png',
      name:'司法部公开征求意见系统'
    },
    {
      img: 'hbzhsf.png',
      name: '河北省行政立法管理系统'
    },
    {
      img: 'bjsgfxwj.png',
      name: '北京市规范性文件审查工作信息平台'
    },
    {
      img: 'hljszfgz.png',
      name: '黑龙江省政府规章和行政规范性文件<br>综合管理平台'
    },
    {
      img: 'lf-case2.png',
      name: '深圳市政府立法工作系统'
    },
    {
      img: 'lf-case3.png',
      name: '济宁市重大行政决策规范性文件<br>合法性审核信息管理系统'
    },
    {
      img: 'xcqzdjc.png',
      name: '北京市西城区重大行政决策及规范性文件<br>管理平台'
    },
    {
      img: 'hfxszscyy.png',
      name: '浙江省东阳市乡镇（街道）<br>合法性数智审查应用'
    },
    {
      img: '',
      name: ''
    },
    {
      img: '',
      name: ''
    }
  ],
  views: [
    {
      name: '智能起草'
    },
    {
      name: '智能汇总'
    },
    {
      name: '版本对比'
    },
    {
      name: '智能处理'
    },
    {
      name: '智能审查'
    },
    {
      name: '智能清理'
    },
  ]
}
