
import { defineComponent } from 'vue'

import ComBox from '@/components/comBox/wComBox.vue'
import part1 from './cpns/part1.vue'
import part2 from './cpns/part2.vue'
import part3 from './cpns/part3.vue'
import part4 from './cpns/part4.vue'
import part5 from './cpns/part5.vue'
import { allData } from '../config/WLegislation.config'

export default defineComponent({
  name: 'WisdomLegislation',
  components: { ComBox, part1, part2, part3, part4, part5 },
  setup() {
    return {
      allData
    }
  }
})
